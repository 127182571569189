import React from "react";

const WhatWeStandFor = () => (
  <div className="flex content-center items-center justify-center">
    <div className="w-full">
      <div className="relative flex flex-col min-w-0 break-words w-full">
        <div className="grid grid-cols-1 blog-post-container text-black">

          <p className="">
            Nodevin believes in a decentralized future.
          </p>
          <p className="">
            Nodevin seeks to make blockchain accessible, efficient, and reliable.
          </p>
          <p className="">
            Nodevin builds morally grounded, sustainable, lasting products.
          </p>
          <p className="">
            Nodevin follows in the footsteps of the greats.
          </p>
          <p className="">
            Nodevin strives to create an inclusive financial system.
          </p>
          <p className="">
            Nodevin finds that disagreement fosters innovation.
          </p>
          <p className="">
            Nodevin stands for decentralization as a moral imperative.
          </p>
          <p className="">
            Nodevin focuses on systems designed to work for people, not for profit.
          </p>
          <p className="">
            Nodevin fights for change that endures.
          </p>
          <p className="">
            Nodevin wishes that its measure of success will be in the positive change it brings to the world.
          </p>
          <p className="">
            <em>
              Upholding transparency and moral integrity requires discipline and resilience, especially in an industry that often gravitates toward shortcuts for rapid growth. While other companies may prioritize immediate gains, we understand that true impact comes from long-term vision and adherence to core principles.
            </em>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default WhatWeStandFor;
