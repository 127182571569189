import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Routes, HashRouter } from 'react-router-dom';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";

// views without layouts
import Index from "views/Index.js";
import Download from "views/Download.js";
import Blog from "views/Blog.js";
import BlogPost from "views/BlogPost.js";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/download" element={<Download/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/blog/:title" element={<BlogPost/>} />
        <Route path="/" element={<Index/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
