import React, { useState } from "react";
import { Link } from "react-router-dom";
import BlogNavbar from "components/Navbars/BlogNavbar.js";
import blogPosts from "blog/Blog.js";
import Footer from "components/Footers/BlogFooter";

export default function Blog() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Number of posts per page

  const categories = ["All", "Technology", "Vision", "Other"];

  // Parse the date strings into Date objects and sort by date descending
  const sortedPosts = blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

  const filteredPosts = selectedCategory === "All"
    ? sortedPosts
    : sortedPosts.filter((post) => post.category === selectedCategory);

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to the first page on category change
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <BlogNavbar fixed />
      <main>
        <section className="relative w-full h-full pt-20 min-h-screen bg-black">
          <div className="container mx-auto px-4 h-full">
            {/* Blog Header */}
            <div className="text-center py-10">
              <h1 className="font-helvetica font-semibold blog-title text-white">Nodevin Blog</h1>
              <p className="text-white font-inconsolata blog-subtext mt-4">Insights from the team.</p>
            </div>

            {/* Blog Content */}
            <div className="flex content-center items-center justify-center">
              <div className="w-full lg:w-10/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h2 className="font-semibold text-3xl text-black">
                        {selectedCategory === "All"
                          ? "All Posts"
                          : `${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Posts`}
                      </h2>
                    </div>
                    <div className="text-center my-6">
                      {categories.map((category) => (
                        <a
                          key={category}
                          className={`m-2 px-0 md:px-4 py-2 blog-selector rounded cursor-pointer ${
                            selectedCategory === category
                              ? "text-black"
                              : "text-gray-500 hover:text-black"
                          }`}
                          onClick={() => handleCategoryChange(category)}
                        >
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </a>
                      ))}
                    </div>
                    <hr className="mt-6 border-b-1 border-black" />
                  </div>

                  {/* Blog Post Cards */}
                  <div className="grid grid-cols-1 gap-8 px-6 pb-6">
                    {currentPosts.map((post, index) => (
                      <div
                        key={index}
                        className="md:px-4 py-4"
                      >
                        <h3 className="blog-selection-title font-semibold mb-2 text-black">{post.title}</h3>
                        <p className="blog-selection-date text-gray-500 mb-4">{post.date}</p>
                        <p className="blog-selection-excerpt text-gray-500 leading-relaxed mb-4">{post.excerpt}</p>
                        <Link to={`/blog/${encodeURIComponent(post.title)}`} className="blog-selection-link text-gray-600 hover:text-black font-semibold">
                          Read More →
                        </Link>
                      </div>
                    ))}
                  </div>

                  {filteredPosts.length === 0 && (
                    <p className="text-center py-10 text-gray-500">No posts available in this category.</p>
                  )}

                  {/* Pagination */}
                  <div className="rounded-t mb-0 px-6">
                    <hr className="mt-6 border-b-1 border-black" />
                    {totalPages > 1 && (
                      <div className="flex justify-center mt-6 pb-6">
                        {[...Array(totalPages)].map((_, i) => (
                          <a
                            key={i + 1}
                            className={`mx-1 px-3 py-1 rounded cursor-pointer ${
                              currentPage === i + 1
                                ? "text-black"
                                : "text-gray-500 hover:text-black"
                            }`}
                            onClick={() => paginate(i + 1)}
                          >
                            {i + 1}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </main>
    </>
  );
}
