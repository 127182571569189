import React from "react";

const WorkingAtNodevin = () => (
  <div className="flex content-center items-center justify-center">
    <div className="w-full">
      <div className="relative flex flex-col min-w-0 break-words w-full">
        <div className="grid grid-cols-1 text-black blog-post-container">
            <p className="">Going out on a limb and starting a company with ambitions to change the world and propel innovation within the blockchain and cryptocurrency space. </p>
            <p className="">Waking up each day determined to make progress. To take a step, no matter how small, toward a world that should exist. </p>
            <p className="">There are no guarantees that this will work, or that the ideas that I present will even hold gravity in a competitive field of developers and scientists. </p>
            <p className="">Regardless, I need to take the step, because I'm not sure that anyone else will. </p>
            <p className="">Reality must be <strong>created</strong>, thus it is our duty to act. </p>
            <p className="">To read, learn, and act upon novel subjects that are advanced every single day. </p>
            <p className="">Could there really be something so unexplored, and yet, so exciting? </p>
            <p className="">Would I have joined Christopher Columbus' ship had I known it would take me to a new world? </p>
            <p className=""><strong>Yes.</strong></p>
            <p className="">That's how it feels to work at Nodevin.</p>
        </div>
      </div>
    </div>
  </div>
);

export default WorkingAtNodevin;
