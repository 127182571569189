import React, { useEffect, useState } from 'react';
import axios from 'axios';

import appleImage from "../assets/img/os-icons/Apple.png"
import linuxImage from "../assets/img/os-icons/Linux.png"
import windowsImage from "../assets/img/os-icons/Windows.png"

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  const [versions, setVersions] = useState({
    windows: { amd64: [], arm64: [] },
    mac: { amd64: [], arm64: [] },
    linux: { amd64: [], arm64: [] },
  });

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get('https://api.github.com/repos/fiftysixcrypto/nodevin/releases');
        const releases = response.data;

        const versionData = {
          windows: { amd64: [], arm64: [] },
          mac: { amd64: [], arm64: [] },
          linux: { amd64: [], arm64: [] },
        };

        releases.forEach(release => {
          const version = release.tag_name;

          release.assets.forEach(asset => {
            if (asset.name.endsWith('.zip') || asset.name.endsWith('.gz')) {
              if (asset.name.includes('windows')) {
                if (asset.name.includes('amd64')) {
                  versionData.windows.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.windows.arm64.push({ version, url: asset.browser_download_url });
                }
              } else if (asset.name.includes('mac')) {
                if (asset.name.includes('amd64')) {
                  versionData.mac.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.mac.arm64.push({ version, url: asset.browser_download_url });
                }
              } else if (asset.name.includes('linux')) {
                if (asset.name.includes('amd64')) {
                  versionData.linux.amd64.push({ version, url: asset.browser_download_url });
                } else if (asset.name.includes('arm64')) {
                  versionData.linux.arm64.push({ version, url: asset.browser_download_url });
                }
              }
            }
          });
        });

        setVersions(versionData);
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);

  return (
    <>
      <IndexNavbar fixed />

      <section className="header relative items-center flex flex-wrap explanatory-viewpoints max-h-860-px">
        <div className="container mx-auto flex flex-wrap md:flex-nowrap items-center">
          {/* Left Side Text */}
          <div className="w-full md:w-4/12 lg:w-3/12 pt-32 sm:pt-0 text-center md:text-left px-4 lg:mr-auto mb-6 md:mb-0">
            <div>
              <h2 className="font-helvetica download-landing-title">Download Nodevin</h2>
              <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
                Nodevin supports all major operating systems.
              </p>
            </div>
          </div>
          {/* Download Table */}
          <div className="download-table w-full md:w-8/12 flex flex-row flex-wrap gap-6 justify-center">
            <div class="download-table flex flex-row flex-wrap gap-6 justify-center">
              <div class="os-box">
                <span class="arm-version">Download ARM64: <a href="{versions.windows.arm64[0]?.url}" target="_blank" rel="noopener noreferrer">{versions.windows.arm64[0]?.version}</a></span>
                <img class="download-image py-16" src={windowsImage} alt="Windows" />
                <a class="download-button" href="{versions.windows.amd64[0]?.url}" target="_blank">Download ({versions.windows.amd64[0]?.version})</a>
              </div>

              <div class="os-box">
                <span class="arm-version">Download ARM64: <a href="{versions.mac.arm64[0]?.url}" target="_blank" rel="noopener noreferrer">{versions.mac.arm64[0]?.version}</a></span>
                <img class="download-image py-16" src={appleImage} alt="Mac" />
                <a class="download-button" href="{versions.mac.amd64[0]?.url}" target="_blank">Download ({versions.mac.amd64[0]?.version})</a>
              </div>

              <div class="os-box">
                <span class="arm-version">Download ARM64: <a href="{versions.linux.arm64[0]?.url}" target="_blank" rel="noopener noreferrer">{versions.linux.arm64[0]?.version}</a></span>
                <img class="download-image py-16" src={linuxImage} alt="Linux" />
                <a class="download-button" href="{versions.linux.amd64[0]?.url}" target="_blank">Download ({versions.linux.amd64[0]?.version})</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="header relative items-center flex max-h-860-px">
        <div className="container mx-auto flex flex-wrap items-center">
          <div className="w-full text-center px-4">
            <div className="pt-32 pb-32 sm:pt-0 sm:pb-16">
              <p className="font-inconsolata mt-4 landing-other-subtext leading-relaxed">
                <i>Nodevin utilizes <a href="https://www.docker.com/" target="_blank"><u>Docker</u></a> to run nodes. Make sure to install it beforehand!</i>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
