import React from "react";
import { useParams, Link } from "react-router-dom";
import BlogNavbar from "components/Navbars/BlogNavbar.js";
import blogPosts from "blog/Blog.js";
import Footer from "components/Footers/BlogFooter";

export default function BlogPost() {
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title);

  const post = blogPosts.find((p) => p.title === decodedTitle);

  if (!post) {
    return <p className="text-center text-white">Post not found</p>;
  }

  return (
    <>
      <BlogNavbar fixed />
      <main>
        <section className="relative w-full h-full py-20 min-h-screen bg-black">
          <div className="container mx-auto px-4 h-full">
            {/* Blog Header */}
            <div className="text-center py-10">
              <h1 className="font-semibold text-4xl text-white">{post.title}</h1>
              <h3 className="text-gray-300 text-xl mt-4">{post.excerpt}</h3>
              <p className="text-gray-300 mt-4"><strong>{post.date}</strong></p>
              <h4 className="text-gray-300 mt-2"><i>{post.category}</i></h4>
            </div>

            {/* Blog Content */}
            <div className="flex content-center items-center justify-center">
              <div className="w-full lg:w-10/12 md:px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                    <div className="rounded-t mb-0 px-6 py-6">
                        <Link to="/blog" className="text-gray-600 hover:text-black font-semibold">
                            ← Back to Blog
                        </Link>
                        <hr className="mt-6 border-b-1 border-black" />
                    </div>

                    <div className="grid grid-cols-1 gap-8 px-6">
                        <div className="md:px-4 py-4">
                            <div className="leading-relaxed">
                                {post.content}
                            </div>
                        </div>
                    </div>

                    <div className="rounded-t mb-0 px-6 py-6">
                        <hr className="mb-6 border-b-1 border-black" />
                        <Link to="/blog" className="text-gray-600 hover:text-black font-semibold">
                            ← Back to Blog
                        </Link>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}
