/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import nodevinWordmark from "../../assets/img/brand-icons/wordmark-inverted.png"

export default function Navbar(props) {
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-black shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between">
            <Link
              to="/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase flex items-center"
            >
              <img className="header-wordmark mx-auto" src={nodevinWordmark} alt="nodevin" />
            </Link>
            <ul className="flex flex-row-reverse sm:flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <a
                  className="text-white px-2 py-4 lg:py-2 flex items-center uppercase font-bold"
                  href=""
                  target="_blank"
                >
                  <i className="text-white fab fa-discord landing-icon leading-lg " />
                </a>
                <a
                  className="text-white px-2 py-4 lg:py-2 flex items-center text-lg uppercase font-bold"
                  href="https://x.com/node_vin"
                  target="_blank"
                >
                  <i className="text-white fab fa-x-twitter landing-icon leading-lg " />
                </a>
                <a
                  className="text-white px-2 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://github.com/fiftysixcrypto/nodevin"
                  target="_blank"
                >
                  <i className="text-white fab fa-github landing-icon leading-lg " />
                </a>
                <a
                  className="text-white px-2 py-4 lg:py-2 flex items-center uppercase font-bold"
                  href="/#/download"
                >
                  <i className="text-white fas fa-regular fa-download landing-icon leading-lg " />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
